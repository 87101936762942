// arr: 传过来的数据数组
// year: 年份

import moment from "dayjs";

export const Clickyear = (arr, year) => {
    let datas = []
    //循环数组每个对象
    datas = arr.filter((res) => {
        if (res.year == year) {
            return true
        }
        // 公司推介材料数据
        if (res.pulishDate) {
            if (res.pulishDate.includes(year)) {
                console.log(res.pulishDate);
                return true
            }
        }
    })
    return datas
}
// 年份列表
export const getyearList = (arr) => {
    // console.log(arr);
    let yearList = []
    arr.map((res) => {
        if (res.year) {
            yearList.push(res.year)
        }
    })
    // 数组去重
    function dedupe(array) {
      let newArr=Array.from(new Set(array));
        newArr.sort(function(a, b) { //callback
            if (a < b) {
              return 1  //返回正数 ，b排列在a之前
            } else {
              return -1 //返回负数 ，a排列在b之前
            }
          })
        return newArr
    }

    return dedupe(yearList)
}



// 公告及通函年份列表
export const getnoticeyearlist = (arr) => {
    let yearList = []
    // arr.map((res) => {
    //     yearList.push(res.date.slice(0, 4))
    // })
    // // 数组去重
    // function dedupe(array) {
    //     return Array.from(new Set(array));
    // }
    // return dedupe(yearList)

    arr.map(item => {
        let year = moment(item.date).year();

        if (yearList.indexOf(year) == -1) {
            yearList.push(year);
        }
    })

    // 对年份进行从大到小顺序排列
    yearList = yearList.sort((a, b) => {
        return b - a;
    })

    return yearList;
}

// 公告及通函选择年份
export const getnoticedatalist = (arr, Dates) => {
    let datas = []
    //循环数组每个对象
    datas = arr.filter((res) => {
        if (res.date.includes(Dates)) {
            return true
        }
    })
    return datas
}
